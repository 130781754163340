<template>
  <div>
    <ContactPropertiesList
      v-for="{ title, statuses } in propertyCategories"
      :key="statuses"
      v-bind="{ title, statuses }"
    />
  </div>
</template>

<script>
import ContactPropertiesList from '@/components/contacts/ContactPropertiesList'

export default {
  name: 'MyProperties',
  components: {
    ContactPropertiesList
  },
  computed: {
    propertyCategories () {
      return [
        { statuses: 'dossier_preparation', title: 'Vastgoed in dossieropbouw' },
        { statuses: 'for_sale', title: 'Vastgoed te koop' },
        { statuses: 'for_rent', title: 'Vastgoed te huur' },
        { statuses: 'for_acquisition', title: 'Vastgoed over te nemen' },
        { statuses: ['sold', 'rented_out', 'bought', 'rented', 'acquired', 'acquired_out'].join(), title: 'Historiek' }
      ]
    }
  }
}
</script>
