<template>
  <div class="tw-relative tw-mb-8 tw-px-4 tw-w-full">
    <div class="tw-my-7.5 tw-flex tw-flex-row tw-items-center">
      <h2 class="tw-pr-7.5 tw-my-2.5">
        {{ title }}
      </h2>
      <!-- Dashed divider -->
      <hr class="tw-border-t-0.5 tw-border-dashed tw-border-gray-cc tw-flex-grow" />
      <!-- Circle -->
      <div class="tw-h-3.5 tw-w-3.5 tw-rounded-full tw-border-2 tw-border-gray-cc" />
    </div>

    <div v-if="initialLoading" class="tw-absolute tw-h-10 tw-top-1/2 tw-left-1/2">
      <i
        class="fal fa-spinner-third fa-spin tw-text-3xl"
      />
    </div>

    <transition-group
      name="fade"
      class="tw-grid sm:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-7.5"
    >
      <div v-for="property in properties.results" :key="property.id">
        <div
          class="tw-shadow-md tw-rounded-md tw-bg-white tw-overflow-hidden tw-min-h-full"
        >
          <img
            :src="property.main_picture_url || noImageAvailable"
            :alt="property.reference"
            loading="lazy"
            class="tw-w-full tw-h-full tw-max-h-[210px] tw-object-cover"
          />
          <div class="tw-p-3.5">
            <router-link :to="{ name: 'PropertyDetails', params: { id: property.id } }">
              <h4>{{ property.reference }}</h4>
            </router-link>
            {{ property.address }} - {{ property.type.name_nl }} - {{ PROPERTY_STATUSES[`status_${property.status_for_user}`] }}

            <div class="tw-flex tw-items-center">
              <FormulateInput
                v-model="property.is_visible_customer_portal"
                type="checkbox"
                name="publish"
                label="Zichtbaar op klantenportaal"
                outer-class="tw-my-2"
                @input="publishProperty(property.id, $event)"
              />
              <div class="tw-inline tw-pt-0.5">
                <a v-if="property.is_visible_customer_portal" :href="property.customer_portal_url" target="_blank">
                  <i class="fa fa-link tw-ml-2 tw-text-xs" title="Klantenportaal als eigenaar bekijken" />
                </a>
              </div>
            </div>

            <div v-if="property.transaction_data">
              <div class="tw-flex tw-flex-wrap tw-justify-between">
                <span class="tw-font-semibold">Intakedatum:</span>
                {{ property.transaction_data.intake_date }}
              </div>
              <div class="tw-flex tw-flex-wrap tw-justify-between">
                <span class="tw-font-semibold">Transactiedatum (uit):</span>
                {{ property.transaction_data.out_date }}
              </div>
              <div class="tw-flex tw-flex-wrap tw-justify-between">
                <span class="tw-font-semibold">Transactieprijs (uit):</span>
                {{ property.transaction_data.out_price }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition-group>

    <span v-text="error" class="tw-text-error" />

    <button
      v-if="properties.next"
      type="button"
      :disabled="loading"
      class="formulate-button tw-my-7.5 tw-mx-auto tw-block"
      @click="loadAll(contactId)"
    >
      <i
        v-if="loading"
        class="fal fa-spinner-third fa-spin tw-mr-2"
      />
      Alle panden tonen
    </button>
  </div>
</template>

<script>
import { getPropertiesForContact } from '@/services/contacts'
import { updatePropertyMarketing } from '@/services/properties'
import { errorModal } from '@/modalMessages'

export default {
  name: 'PropertiesList',
  props: {
    statuses: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  constants: {
    PROPERTY_STATUSES: {
      status_dossier_preparation: 'Dossieropbouw',
      status_acquired: 'Overgenomen',
      status_acquired_out: 'Overgelaten',
      status_bought: 'Gekocht',
      status_for_acquisition: 'Over te nemen',
      status_for_rent: 'Te huur',
      status_for_sale: 'Te koop',
      status_rented: 'Gehuurd',
      status_rented_out: 'Verhuurd',
      status_sold: 'Verkocht'
    }
  },
  data () {
    return {
      initialLoading: false,
      loading: false,
      message: null,
      properties: {
        count: 0,
        next: null,
        previous: null,
        results: []
      },
      error: null
    }
  },
  computed: {
    noImageAvailable () {
      return require('@/assets/img/property_dummy.gif')
    },
    contactId  () {
      return this.$route.params.id
    }
  },
  watch: {
    contactId: {
      immediate: true,
      handler (contactId) {
        this.init(contactId)
      }
    }
  },
  methods: {
    async init (contactId) {
      this.initialLoading = true
      const response = await this.fetchPropertiesForStatus(contactId, {
        statuses: this.statuses,
        page_size: 4
      })
      this.initialLoading = false
      return response
    },
    async loadAll (contactId) {
      this.loading = true
      const response = await this.fetchPropertiesForStatus(contactId, {
        statuses: this.statuses,
        page_size: 1000
      })
      this.loading = false
      return response
    },
    async fetchPropertiesForStatus (contactId, params) {
      try {
        this.error = null
        const response = await getPropertiesForContact(contactId, params)
        this.properties = response.data
        return response
      } catch (error) {
        this.error = 'Fout bij ophalen van eigenschappen, probeer het opnieuw.'
      }
    },
    async publishProperty (propertyId, is_visible_customer_portal) {
      try {
        return await updatePropertyMarketing(propertyId, { is_visible_customer_portal })
      } catch (error) {
        errorModal('Fout bij het wijzigen van de zichtbaarheid van het pand.')
      }
    }
  }
}
</script>
